import { graphql } from "gatsby"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import SEO, { getSeoProps } from "../../components/Common/SEO"
import getBannerProps from "../../components/Hero/getHeroBannerProps"
import HeroForProducers from "../../components/Hero/HeroForProducers"

const ForProducerTemplate = ({ location, data }) => {
  // const mainPath = getMainPath("producers")

  const { contentfulGeneric: content } = data

  // TODO: Add test for empty array. this will happen when now articles are related to a topic.
  // const allContent = content.article

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content, location.href)

  // const relatedProps: RelatedSectionProps = {
  //   title: "Les våre artikler som omhandler tema: " + content.title,
  //   path: "nb" + mainPath,
  //   data: allContent,
  // }

  return (
    <>
      <SEO {...helmetProps} />
      <main>
        <article>
          <div className="bg-white overflow-hidden  max-w-200 mx-auto ">
            <HeroForProducers {...heroBannerProps} />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  mb-48">
              <div className="mx-auto max-w-screen md:max-w-2xl pl-4 md:px-8 lg:max-w-3xl xl:max-w-116">
                {content.body.childMdx.body ? (
                  <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
                ) : (
                  "Body mangler i contentful"
                )}
              </div>
            </div>
          </div>
        </article>
      </main>
      {/* <RelatedSection {...relatedProps} /> */}
    </>
  )
}

export default ForProducerTemplate

export const topicQuery = graphql`
  {
    contentfulGeneric(node_locale: { eq: "nb-NO" }, slug: { eq: "produksjonsplanlegging-regelverk" }) {
      title
      id
      contentful_id
      metadataDescription
      slug
      node_locale
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
          }
        }
        url
        secure_url
        width
        height
        format
      }
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
    }
  }
`
